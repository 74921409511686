import '@splidejs/react-splide/css/sea-green'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import useTranslation from 'next-translate/useTranslation'

const ProfileCard = () => {
  const { lang } = useTranslation()
  const slides = [
    {
      id: 'picture1',
      src: `/request-service-img/${lang}/picture1.png`
    },
    {
      id: 'picture2',
      src: `/request-service-img/${lang}/picture2.png`
    },
    {
      id: 'picture3',
      src: `/request-service-img/${lang}/picture3.png`
    },
    {
      id: 'picture4',
      src: `/request-service-img/${lang}/picture4.png`
    },
    {
      id: 'picture5',
      src: `/request-service-img/${lang}/picture5.png`
    }
  ]

  return (
    <Splide
      options={{
        loop: true,
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        arrows: true,
        autoplay: true,
        interval: 5000,
        direction: lang === 'ar' ? 'rtl' : 'ltr',
        pagination: true,
        lazyLoad: 'nearby',
        pauseOnHover: true
      }}
      className='!px-[0.5rem]'
    >
      {slides.map(({ id, src }) => (
        <SplideSlide
          key={id}
          className='flex justify-center items-center'
        >
          <img
            className='w-[90%]'
            src={src}
            alt={id}
          />
        </SplideSlide>
      ))}
    </Splide>
  )
}

export default ProfileCard
